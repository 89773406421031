<template>
  <main>
     <!-- Footer start -->
    <footer v-if="info" class="main-footer clearfix">
            <!-- Footer info-->
        <div class="container ">
            <div class="footer-info ">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="logo-2">
                            <img v-if="info.logo" :src="info.logo" :alt="info.alt">
                            <!-- <img v-else-if="info.logo" :src="info.logo" :alt="info.alt"> -->
                            <h3 v-else>{{info.companyName}}</h3>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <ul class="social-list clearfix">
                            <li><a v-if="info.instagram && info.instagram!='0'" :href="info.instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a v-if="info.pinterest && info.pinterest!='0'" :href="info.pinterest" target="_blank"><i class="fab fa-pinterest-p"></i></a></li>
                            <li><a v-if="info.youtube && info.youtube!='0'" :href="info.youtube" target="_blank"><i class="fab fa-youtube"></i></a></li>
                            <li><a v-if="info.linkedin && info.linkedin!='0'" :href="info.linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a v-if="info.tw && info.tw!='0'" :href="info.tw" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a v-if="info.fb && info.fb!='0'" :href="info.fb" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        </ul>
                    </div>
                    <!-- About us -->
                    <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                        <div class="footer-item">
                            <div class="main-title-2">
                                <h1>Contáctanos</h1>
                            </div>
                            <ul class="personal-info">
                                <li v-if="info.address">
                                    <i class="fas fa-map-marker-alt"></i>
                                    {{info.address}}
                                </li>
                                <li v-if="info.mail">
                                    <i class="fa fa-envelope"></i>
                                    <a :href="`mailto:${info.mail}`">{{info.mail}}</a>
                                </li>
                                <li v-if="info.phone">
                                    <i class="fa fa-phone"></i>
                                    {{info.phone}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer end -->

    <!-- Copy right start -->
    <div class="copy-right">
        <div class="container">
            Todos los derechos reservados. {{year}} | Powered with <i class="far fa-heart"></i> & <i class="lnr lnr-coffee-cup"></i> by <a href="https://www.immosystem.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a>
        </div>
    </div>
    <!-- Copy end right--> 
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            year:""
        }
    },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
    created(){
        let d= new Date();
        this.year=d.getFullYear();
    }
}
</script>