<template>
  <div id="app">
     <Back v-if="$route.name!='Properties'"/>
      <AgentInfo/>
      <!--Header/-->
      <router-view/>
      <Footer/>
  </div>
</template>
<script>
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import AgentInfo from '@/components/layout/AgentInfo'
import Back from '@/components/layout/BackButton'
export default {
  components:{
    Header,
    Footer,
    AgentInfo,
    Back
  },
  data(){
    return{
      loader:true
    }
  },
  created(){
    /*let data={
          idCompany:this.$route.params.idCompany,
          codeShareProperties:this.$route.params.codeShareProperties
      }
      this.$store.dispatch("getProperties", data);*/
  
    this.$store.dispatch('getCompanyMetadata');
    setTimeout(()=>{
      this.loader=false
    },2000);
  }
}
</script>
<style>
/* Loader Page*/
  .loader-page-content{
    height: 100vh;
    padding:  30vh 1vw;
  }

  
  .watermark,
  .lb-container{ 
    /*background:transparent url('./assets/images/logoImmo/immoCRMLogo.png') no-repeat;
    */
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center;
    
    object-position: center;
    position: absolute;
    width: 100%;
  }
  .watermark{
    opacity: 0.9;
  }
</style>

