import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from 'vue-sweetalert2';
import VueTelInput from 'vue-tel-input';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueGeolocation from 'vue-browser-geolocation';

Vue.config.productionTip = false

Vue.use(VueTelInput);
Vue.use(VueGeolocation);
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA3mcj07-7u_nSPUosA0IuwIU3WPnP0D6I"
  }
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
