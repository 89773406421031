import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '@/views/Error/'
import Home from '@/views/Properties/'
import Property from '@/views/Properties/Detail/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/404'
  },
  {
    path: '/propiedades/:codeShareProperties/:idCompany',
    name: 'Properties',
    component: Home
  },
  {
    path: '/propiedad/:agentId/:folio/:namePropFilt',
    name: 'Property',
    component: Property,
  },
  {
    path: '/404',
    name: 'Error',
    component: Error
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
