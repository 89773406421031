<template>
    <!-- Error404 content start -->
    <div class="error404-content">
        <h1>{{code}} <i class='fa fa-sad-tear'></i></h1>
        <h3>OOPS ESTA PÁGINA NO PUEDE ENCONTRARSE!</h3>
        <p>La página que busca no parece existir. Intenta cambiando a una URL valida.</p>
    </div>
    <!-- Error404 content end -->
</template>

<script>
export default {
    name:'notFound',
    props:{
        code:{
            type:String,
            required:true
        }
  }
}
</script>

<style>

</style>